import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useParams } from 'react-router-dom';
import {
  Card,
  Form,
  FormProps,
  Row,
  Col,
  Input,
  // AutoComplete,
  Select,
  DatePicker,
  InputNumber,
  DatePickerProps,
  Button,
  Table,
  notification,
  Checkbox
} from 'antd';
import { useMutation } from '@tanstack/react-query';
import { PlusOutlined, CalendarOutlined, CloseOutlined } from '@ant-design/icons';

import GenerateGroupBookingModal from 'components/modal/group/generate-group-booking-modal';

import { useGetCustomers } from 'hooks/useGetCustomers';
import { useGetSources } from 'hooks/useGetSources';
import { useGetCompanies } from 'hooks/useGetCompanies';
import { useGetGroupBookingDetail } from 'hooks/useGetGroupBookings';
import useModalStore from 'stores/useModal';

import { MESSAGE_CODE } from 'constants/validate';
import { CUSTOMER_TYPE, VIP_LEVEL_OPTIONS } from 'constants/form';
import { ACTION_SUBGROUP, getColumnDetailGroup } from 'constants/table';
import { ParamsAddSubGroup, ParamsGroupBooking, SubGroup } from 'services/api/type/group.type';
// import { RangeValueType } from 'services/api/type/common.type';
// import { getDateTodayMin2PM, getNextDateAt } from 'utils';

import {
  cancelSubgroup,
  updateGroupBooking,
  cancelGroupBooking
} from 'services/api/module/group.api';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
// import { ReactComponent as IconMale } from 'assets/images/male.svg';
// import { ReactComponent as IconEmail } from 'assets/images/email.svg';
import 'styles/detail-group.scss';

export const disabledLastDeparture = (current: Dayjs, arrival: Dayjs) => {
  // Can not select days after today and today
  return current && current <= arrival;
};

const today = dayjs();
const disabledDate: DatePickerProps['disabledDate'] = current => {
  return current.isBefore(today);
};

function DetailGroup() {
  const { groupId } = useParams();
  const [form] = Form.useForm();
  const nameForm = Form.useWatch('name', form);
  const arrivalForm = Form.useWatch('first_arrival', form);

  const {
    subGroupDetail,
    setIsOpenSubGroupDetail,
    setSubGroupDetail,
    setIsOpenChangeDateGroupBooking,
    setInfoConfirmModal,
    setConfirmLoading
  } = useModalStore();

  // const [searchName, setSearchName] = useState('');
  const [searchCompany, setSearchCompany] = useState('');
  // const [rangeDate, setRangeDate] = useState<RangeValueType>([
  //   getDateTodayMin2PM(),
  //   getNextDateAt(1, 12)
  // ]);
  const [subGroups, setSubGroups] = useState<SubGroup[]>([]);
  const [isShowGenerateGroup, setIsShowGenerateGroup] = useState(false);

  const { data: companies } = useGetCompanies();
  const { data: sources } = useGetSources();

  // const { data: customers } = useGetCustomers(
  //   searchName,
  //   CUSTOMER_TYPE.PERSONAL,
  //   !!searchName && searchName.length > 3
  // );
  const { data: dataCompany, isFetching } = useGetCustomers(
    { search: searchCompany },
    CUSTOMER_TYPE.BUSINESS
  );

  const { data: dataGroup, isFetching: isFetchingGroup } = useGetGroupBookingDetail(
    Number(groupId)
  );

  const isCancelled: boolean = dataGroup?.state === 'cancelled';

  const { mutateAsync: mutateCancelSubGroup } = useMutation({
    mutationFn: (params: ParamsAddSubGroup) => {
      return cancelSubgroup(Number(subGroupDetail?.id), params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GROUP_BOOKING_DETAIL, Number(groupId)]
      });
    }
  });

  const { mutateAsync: mutateUpdateSubGroup, isPending: isPendingUpdate } = useMutation({
    mutationFn: (params: Partial<ParamsGroupBooking>) => {
      return updateGroupBooking(Number(groupId), params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GROUP_BOOKING_DETAIL, Number(groupId)]
      });
    }
  });

  const { mutateAsync: mutateCancelGroupBooking, isPending: isPendingCancelGroupBooking } =
    useMutation({
      mutationFn: () => {
        return cancelGroupBooking(Number(groupId));
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GROUP_BOOKING_DETAIL, Number(groupId)]
        });
      }
    });

  useEffect(() => {
    const pmsAppContent = document.querySelector('.pms-app-content');
    if (pmsAppContent) {
      pmsAppContent.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(dataGroup)) {
      const startDate = dayjs(dataGroup.first_arrival);
      const endDate = dayjs(dataGroup.last_departure);
      const night = endDate
        .set('hour', 23)
        .set('minute', 59)
        .diff(startDate.set('hour', 0).set('minute', 0), 'day');

      form.setFieldsValue({
        id: dataGroup.id,
        name: dataGroup.name,
        booker: null,
        travel_agency_id: dataGroup.travel_agency_id,
        code: dataGroup.code,
        // status: '',
        vip_level: dataGroup.vip_level,
        medium_id: dataGroup.medium_id,
        source_id: dataGroup.source_id,
        first_arrival: startDate,
        last_departure: endDate,
        release_date: dayjs(dataGroup.release_date),
        night,
        note: dataGroup.note ? dataGroup.note : '',
        pricelist_id: '',
        ref_code: dataGroup.ref_code,
        has_breakfast: dataGroup.has_breakfast
      });
      setSubGroups(dataGroup.sub_groups);
    }
  }, [dataGroup, form]);

  // const { data: availRoomTypes, isFetching: isFetchingRoomTypes } = useGetAvailRoomType(
  //   toUtcTime(rangeDate[0], 'YYYY-MM-DD HH:mm:ss'),
  //   toUtcTime(rangeDate[1], 'YYYY-MM-DD HH:mm:ss')
  // );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const handleSearchName = useCallback(
  //   _.debounce((value: string) => {
  //     setSearchName(value);
  //   }, 500),
  //   []
  // );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchCompany = useCallback(
    _.debounce((value: string) => {
      setSearchCompany(value);
    }, 500),
    []
  );

  // const handleSelectCustomerAutoComplete = (customerId: number) => {
  //   const customer = _.find(customers, { id: customerId });

  //   if (customer) {
  //     const data: {
  //       [key: string]: any;
  //     } = {
  //       partner_id: customer.id,
  //       name: customer.name,
  //       gender: customer.gender,
  //       phone: customer.phone,
  //       email: customer.email,
  //       dob: customer.dob ? dayjs(customer.dob) : undefined,
  //       identification: customer.identification,
  //       country_id: customer.country?.id,
  //       state_id: customer.state?.id,
  //       street: customer.street,
  //       comment: customer.comment,
  //       attachments: customer.attachments
  //     };
  //     const truthyValues: any = Object.keys(data)
  //       .filter(key => Boolean(data[key]))
  //       .reduce((cur, next) => {
  //         return {
  //           ...cur,
  //           [next]: data[next]
  //         };
  //       }, {});

  //     form.setFieldValue('booker', truthyValues);
  //   }
  // };

  const handleChangeArrival = (day: Dayjs) => {
    const lastDeparture = day.add(1, 'day');
    form.setFieldValue('lastDeparture', lastDeparture);

    const newBookings = subGroups.map(item => {
      return {
        ...item,
        from_date: day,
        to_date: lastDeparture
      };
    });
    setSubGroups(newBookings);
    form.setFieldValue('night', 1);

    // let startDate = day;
    // const now = dayjs();
    // const minNow = now.hour(14).minute(0).second(0).millisecond(0);
    // startDate = now.valueOf() > minNow.valueOf() ? now : minNow;
    // const endDate = startDate.add(1, 'day').hour(12).minute(0).second(0).millisecond(0);
    // setRangeDate([startDate, endDate]);
  };

  const handleChangeDeparture = (day: Dayjs) => {
    const startDate: Dayjs = _.clone(arrivalForm);

    const endDate: Dayjs = _.clone(day);
    const night = endDate
      .set('hour', 23)
      .set('minute', 59)
      .diff(startDate.set('hour', 0).set('minute', 0), 'day');
    form.setFieldValue('night', night);

    // setRangeDate([startDate.hour(14).minute(0).second(0), endDate.hour(12).minute(0).second(0)]);
  };

  // const handleChangePricing = (value: number) => {
  //   const newBookings = bookings.map(item => {
  //     const roomType = _.find(availRoomTypes, { room_type_name: item.room_type_name });
  //     const rateAmount = _.find(roomType?.pricing, { pricelist_id: value });
  //     return {
  //       ...item,
  //       rateAmount: rateAmount?.price || 0
  //     };
  //   });
  //   setSubGroups(newBookings);
  // };

  const handleShowAddNewSubGroup = () => {
    setSubGroupDetail(undefined);
    setIsOpenSubGroupDetail(true);
  };

  const handleShowChangeDate = () => {
    setIsOpenChangeDateGroupBooking(true);
  };

  const handleCancelSubGroup = async (subGroupInfo: SubGroup | undefined) => {
    if (subGroupInfo) {
      try {
        setConfirmLoading(true);
        await mutateCancelSubGroup({
          check_in: dayjs(subGroupInfo?.check_in).format('YYYY-MM-DD'),
          check_out: dayjs(subGroupInfo?.check_out).format('YYYY-MM-DD'),
          room_type_id: subGroupInfo.room_type_id,
          quantity: subGroupInfo.quantity,
          rate_amount: subGroupInfo.rate_amount,
          pax: subGroupInfo.pax
        });

        setInfoConfirmModal(false);
      } catch (err: any) {
        notification.error({
          message: err.error || 'Có lỗi xảy ra'
        });
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  const confirmCancelGroupBooking = () => {
    setInfoConfirmModal(true, {
      title: 'Xác nhận hủy đoàn',
      onOk: () => {
        handleCancelGroupBooking();
      }
    });
  };

  const handleCancelGroupBooking = async () => {
    try {
      await mutateCancelGroupBooking();
      notification.success({
        message: 'Hủy đoàn thành công'
      });
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra khi hủy đoàn'
      });
    } finally {
      setInfoConfirmModal(false);
    }
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      const params: Partial<ParamsGroupBooking> = {
        name: values.name,
        travel_agency_id: values.travel_agency_id,
        release_date: values.release_date.format('YYYY-MM-DD'),
        has_breakfast: Boolean(values.has_breakfast)
      };

      ['vip_level', 'medium_id', 'source_id', 'note', 'ref_code'].forEach(key => {
        if (values[key]) {
          _.set(params, key, values[key]);
        }
      });

      // await fetch(
      //   `https://api-pms-dev.kinliving.vn/api${paths.updateGroupBooking(Number(groupId))}`,
      //   {
      //     method: 'PUT',
      //     body: JSON.stringify(params),
      //     headers: {
      //       'Content-Type': 'application/json',
      //       'Access-Control-Allow-Origin': '*',
      //       'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Z-Key',
      //       'Access-Control-Allow-Methods': 'GET, HEAD, POST, PUT, DELETE, OPTIONS',
      //       Authorization:
      //         'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo4OSwiaXNfZXh0ZXJuYWwiOmZhbHNlLCJleHAiOjE3MjYwNTQ5NTR9.BXnoIvlRVQEXOMUVwVrQMwSlRpAOrdl-7JZIxVS6-N8|1'
      //     }
      //   }
      // );

      await mutateUpdateSubGroup(params);

      notification.success({
        message: 'Sửa group thành công'
      });
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  const detailGroupCols = useMemo(() => {
    const handleClickMenuSubgroup = (menu: any) => {
      switch (menu.key) {
        case ACTION_SUBGROUP.DETAIL:
          setIsOpenSubGroupDetail(true);
          break;
        case ACTION_SUBGROUP.GENERATE_ROOM_LIST:
          setIsShowGenerateGroup(true);
          break;
        case ACTION_SUBGROUP.CANCEL_GROUP:
          setInfoConfirmModal(true, {
            title: 'Xác nhận xóa Sub group',
            onOk: () => {
              handleCancelSubGroup(subGroupDetail);
            }
          });
          break;
        default:
          break;
      }
    };
    return getColumnDetailGroup(handleClickMenuSubgroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subGroupDetail]);

  // const renderCustomerOptions = useMemo(() => {
  //   return customers?.map(item => ({
  //     label: (
  //       <div className="customer-autocomplete-item">
  //         <div className="flex items-center justify-between">
  //           <div className="flex items-center" style={{ gap: '8px' }}>
  //             <IconMale />
  //             <p className="m-0 truncate" style={{ maxWidth: '200px' }}>
  //               {item.name}
  //             </p>
  //           </div>
  //           <div className="flex items-center" style={{ gap: '8px' }}>
  //             <IdcardOutlined />
  //             <p className="m-0 truncate" style={{ maxWidth: '200px' }}>
  //               {item.identification || '-'}
  //             </p>
  //           </div>
  //         </div>
  //         <div className="flex items-center justify-between">
  //           <div className="flex items-center" style={{ gap: '8px' }}>
  //             <IconEmail />
  //             <p className="m-0 truncate" style={{ maxWidth: '200px' }}>
  //               {item.email || '-'}
  //             </p>
  //           </div>
  //           <div className="flex items-center" style={{ gap: '8px' }}>
  //             <PhoneFilled />
  //             <p className="m-0 truncate" style={{ maxWidth: '200px' }}>
  //               {item.phone || '-'}
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //     ),
  //     value: item.id
  //   }));
  // }, [customers]);

  return (
    <div className="pms-detail-group">
      <Card title="Chi tiết đoàn" style={{ width: '100%' }}>
        <Form
          form={form}
          name="detail-group-form"
          layout="horizontal"
          initialValues={{
            name: '',
            booker: null,
            travel_agency_id: '',
            code: '',
            vip_level: '',
            medium_id: '',
            source_id: '',
            first_arrival: dayjs(),
            last_departure: dayjs().add(1, 'day'),
            release_date: dayjs(),
            night: 1,
            note: '',
            pricelist_id: ''
          }}
          onFinish={onFinish}
          autoComplete="off"
          wrapperCol={{ span: 18 }}
          labelCol={{ span: 6 }}
        >
          <div className="form-inner">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12} xl={7}>
                <Form.Item
                  name="name"
                  label="Tên đoàn"
                  rules={[
                    {
                      required: true,
                      message: MESSAGE_CODE.REQUIRED_GROUP_NAME
                    }
                  ]}
                >
                  <Input className="w-full" />
                </Form.Item>

                {/* <Form.Item
                  label="Người đặt"
                  name={['booker', 'name']}
                  style={{ flexGrow: 5 }}
                  rules={[
                    {
                      required: true,
                      message: MESSAGE_CODE.REQUIRED_FULL_NAME
                    }
                  ]}
                >
                  <AutoComplete
                    allowClear
                    placeholder="Tìm kiếm tên, phone hoặc tạo mới"
                    popupClassName="autocomplete-customer"
                    onSearch={handleSearchName}
                    onSelect={handleSelectCustomerAutoComplete}
                    options={renderCustomerOptions}
                    popupMatchSelectWidth={false}
                  />
                </Form.Item> */}

                <Form.Item label="TA.Company" name="travel_agency_id">
                  <Select
                    showSearch
                    allowClear
                    onClear={() => setSearchCompany('')}
                    placeholder="Chọn Công ty"
                    optionFilterProp="children"
                    loading={isFetching}
                    onSearch={handleSearchCompany}
                  >
                    {dataCompany?.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {`${item.id} - ${item.name}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item label="Ăn sáng" name="has_breakfast" valuePropName="checked">
                  <Checkbox />
                </Form.Item>
                {/* <Form.Item label="Loại giá" name="pricing">
                  <Select options={pricelistOptions} onChange={handleChangePricing} />
                </Form.Item> */}
              </Col>

              <Col xs={24} md={12} xl={9}>
                <Form.Item name="id" label="Confirm#">
                  <Input className="w-full" disabled />
                </Form.Item>

                <Form.Item name="code" label="Group Code">
                  <Input className="w-full" disabled />
                </Form.Item>

                {/* <Form.Item label="Status" name="status">
                  <Select />
                </Form.Item> */}

                <Form.Item label="VIP level" name="vip_level">
                  <Select options={VIP_LEVEL_OPTIONS} />
                </Form.Item>

                <Form.Item name="medium_id" label="Thị trường">
                  <Select
                    options={companies}
                    showSearch
                    filterOption={(input, option) =>
                      !!option && option.name.toLowerCase().includes(input.toLowerCase())
                    }
                    fieldNames={{ label: 'name', value: 'id' }}
                  />
                </Form.Item>

                <Form.Item name="source_id" label="Chọn nguồn">
                  <Select
                    options={sources}
                    showSearch
                    filterOption={(input, option) =>
                      !!option && option.name.toLowerCase().includes(input.toLowerCase())
                    }
                    fieldNames={{ label: 'name', value: 'id' }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} xl={8}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      name="first_arrival"
                      label="First Arrival"
                      wrapperCol={{ span: 12 }}
                      labelCol={{ span: 12 }}
                    >
                      <DatePicker
                        onChange={handleChangeArrival}
                        disabledDate={disabledDate}
                        disabled
                      />
                    </Form.Item>

                    <Form.Item
                      name="last_departure"
                      label="Last Departure"
                      wrapperCol={{ span: 12 }}
                      labelCol={{ span: 12 }}
                    >
                      <DatePicker
                        onChange={handleChangeDeparture}
                        disabledDate={current => disabledLastDeparture(current, arrivalForm)}
                        disabled
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="night"
                      label="Night"
                      wrapperCol={{ span: 12 }}
                      labelCol={{ span: 12 }}
                    >
                      <InputNumber className="w-full" readOnly />
                    </Form.Item>

                    <Form.Item
                      name="release_date"
                      label="Release Date"
                      wrapperCol={{ span: 12 }}
                      labelCol={{ span: 12 }}
                    >
                      <DatePicker disabledDate={disabledDate} />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item name="note" label="Note" style={{ marginLeft: '-4px' }}>
                  <Input.TextArea rows={5} className="w-full" />
                </Form.Item>

                <Form.Item name="ref_code" label="Ref code">
                  <Input className="w-full" />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="detail-group-actions">
            <Button
              className="ant-btn-secondary"
              htmlType="submit"
              loading={isPendingUpdate}
              disabled={isCancelled}
            >
              Cập nhật đoàn
            </Button>
            <Button
              className="ant-btn-secondary"
              icon={<CloseOutlined />}
              loading={isPendingCancelGroupBooking}
              onClick={confirmCancelGroupBooking}
              disabled={isCancelled}
            >
              Hủy đoàn
            </Button>
          </div>
        </Form>
      </Card>

      <div className="detail-group-table">
        <Card title="Sub group" className="w-full">
          <h1>Đoàn {nameForm}</h1>
          <Table
            rowKey="id"
            loading={isFetchingGroup}
            columns={detailGroupCols}
            dataSource={subGroups}
            locale={{
              emptyText: <span className="empty-data">Không có dữ liệu</span>
            }}
            scroll={{ x: 1000, y: 'calc(100vh - 320px)' }}
            pagination={false}
            onRow={record => {
              return {
                onClick: () => {
                  setSubGroupDetail(record);
                } // click row
              };
            }}
          />

          <div className="detail-group-table__actions">
            <Button
              className="ant-btn-secondary"
              icon={<PlusOutlined />}
              onClick={handleShowAddNewSubGroup}
              disabled={isCancelled}
            >
              Thêm Sub group
            </Button>

            <Button
              className="ant-btn-secondary"
              icon={<CalendarOutlined />}
              onClick={handleShowChangeDate}
              disabled={isCancelled}
            >
              Sửa ngày
            </Button>
          </div>
        </Card>
      </div>

      <GenerateGroupBookingModal
        isShow={isShowGenerateGroup}
        onCancel={() => setIsShowGenerateGroup(false)}
      />
    </div>
  );
}

export default DetailGroup;
