import React from 'react';
import { Row, Col, Form, Input, Checkbox, Select } from 'antd';
import { MESSAGE_CODE } from 'constants/validate';
import { EmailRegex } from 'constants/regex';

interface FormRowProps {
  countries: any;
  companies: any;
  sources: any;
}

export const renderFormRow = ({ countries, companies, sources }: FormRowProps) => {
  return (
    <>
      <Row gutter={[24, 16]}>
        <Col xs={24} md={12}>
          <Form.Item
            label="Tên công ty, đại lý du lịch"
            name="name"
            rules={[{ required: true, message: MESSAGE_CODE.REQUIRED_TRAVEL_AGENCY_NAME }]}
          >
            <Input placeholder="Nhập tên công ty, đại lý du lịch" />
          </Form.Item>

          <Form.Item
            label="Tên hóa đơn"
            name="invoice_name"
            rules={[{ required: true, message: MESSAGE_CODE.REQUIRED_INVOICE_NAME }]}
          >
            <Input placeholder="Nhập tên hóa đơn" />
          </Form.Item>

          <Form.Item label="ID bên ngoài" name="external_id">
            <Input placeholder="Nhập ID bên ngoài" />
          </Form.Item>

          <Form.Item label="Mã số thuế" name="vat">
            <Input placeholder="Nhập mã số thuế" />
          </Form.Item>

          <Row gutter={[12, 0]}>
            <Col span={12}>
              <Form.Item name="is_travel_agency" valuePropName="checked">
                <Checkbox>TA</Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="is_company" valuePropName="checked">
                <Checkbox>Công ty</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                pattern: EmailRegex,
                message: MESSAGE_CODE.WRONG_FORMAT_EMAIL
              }
            ]}
          >
            <Input placeholder="Nhập email" />
          </Form.Item>

          <Form.Item name="medium_id" label="Phân khúc thị trường">
            <Select
              options={companies}
              showSearch
              allowClear
              filterOption={(input, option) =>
                !!option && option.name.toLowerCase().includes(input.toLowerCase())
              }
              fieldNames={{ label: 'name', value: 'id' }}
              placeholder="Chọn phân khúc thị trường"
            />
          </Form.Item>

          <Form.Item name="source_id" label="Chọn nguồn">
            <Select
              options={sources}
              showSearch
              allowClear
              filterOption={(input, option) =>
                !!option && option.name.toLowerCase().includes(input.toLowerCase())
              }
              fieldNames={{ label: 'name', value: 'id' }}
              placeholder="Chọn nguồn"
            />
          </Form.Item>

          <Form.Item label="Quốc gia" name="country_id">
            <Select
              showSearch
              allowClear
              filterOption={(input, option) =>
                !!option && option.name.toLowerCase().includes(input.toLowerCase().trim())
              }
              options={countries || []}
              fieldNames={{ label: 'name', value: 'id' }}
              placeholder="Chọn quốc gia"
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label="Ghi chú" name="notes">
            <Input.TextArea placeholder="Nội dung" rows={4} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
