import type { Dayjs } from 'dayjs';
import { Document, Font, Page, PDFViewer } from '@react-pdf/renderer';
import { Button, Modal } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import useModal from 'stores/useModal';
import useCommonStore from 'stores/useCommon';

import { HotelInfo, Title, Table } from './components';
import styles from './style-sheet';

// Register font
Font.register({
  family: 'Inter',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf',
      fontWeight: 100
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf',
      fontWeight: 200
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf',
      fontWeight: 300
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
      fontWeight: 400
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf',
      fontWeight: 500
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf',
      fontWeight: 600
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
      fontWeight: 700
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf',
      fontWeight: 800
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf',
      fontWeight: 900
    }
  ]
});

interface IExportPDFProps {
  titlePDF: string;
  enableView?: boolean;
  pagePDFConfig?: typeof Page;
  fromDate?: Dayjs;
  toDate?: Dayjs;
  dataSource: any[];
}

const ExportPDF = ({
  titlePDF,
  pagePDFConfig,
  enableView,
  fromDate,
  toDate,
  dataSource
}: IExportPDFProps) => {
  const { isPDFOpen, setIsPDFOpen } = useModal(state => ({
    isPDFOpen: state.isPDFOpen,
    setIsPDFOpen: state.setIsPDFOpen
  }));

  const { currentBranch } = useCommonStore(state => ({ currentBranch: state.currentBranch }));

  return (
    <>
      <Button icon={<DownloadOutlined />} onClick={() => setIsPDFOpen(true)} disabled={enableView}>
        Xuất PDF
      </Button>
      <Modal
        title={titlePDF}
        centered
        width={1200}
        open={isPDFOpen}
        onCancel={() => setIsPDFOpen(false)}
        footer={null}
        destroyOnClose
      >
        <PDFViewer
          style={{
            width: '100%',
            minHeight: '650px'
          }}
        >
          <Document title={titlePDF} language="vi">
            <Page wrap size="A3" style={styles.page} {...pagePDFConfig}>
              <HotelInfo currentBranch={currentBranch} />
              <Title title={titlePDF} fromDate={fromDate} toDate={toDate} />
              <Table tableData={dataSource} />
            </Page>
          </Document>
        </PDFViewer>
      </Modal>
    </>
  );
};

export default ExportPDF;
