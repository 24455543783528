import { TableCell, TableHeader, Table as TablePDF } from '@ag-media/react-pdf-table';
import { Text } from '@react-pdf/renderer';

import { formatNumber } from 'utils';
import styles from '../style-sheet';

interface ITableProps {
  tableData: any[];
}

const Table = ({ tableData }: ITableProps) => {
  return (
    <TablePDF>
      <TableHeader>
        <TableCell weighting={0.1} style={styles.tableHeader}>
          <Text style={{ ...styles.textExSmallBold, fontSize: 10, fontWeight: 700 }}>DATE</Text>
        </TableCell>
        <TableCell weighting={0.9}>
          <TablePDF weightings={[0.2, 0.2, 0.3, 0.3]}>
            <TableHeader>
              <TableCell style={{ ...styles.tableHeader, justifyContent: 'center' }}>
                <Text style={{ ...styles.textExSmallBold, fontSize: 10, fontWeight: 700 }}>
                  CHECK IN
                </Text>
              </TableCell>
              <TableCell style={{ ...styles.tableHeader, justifyContent: 'center' }}>
                <Text style={{ ...styles.textExSmallBold, fontSize: 10, fontWeight: 700 }}>
                  CHECK OUT
                </Text>
              </TableCell>
              <TableCell style={{ ...styles.tableHeader, justifyContent: 'center' }}>
                <Text style={{ ...styles.textExSmallBold, fontSize: 10, fontWeight: 700 }}>
                  OCCUPIED
                </Text>
              </TableCell>
              <TableCell style={{ ...styles.tableHeader, justifyContent: 'center' }}>
                <Text style={{ ...styles.textExSmallBold, fontSize: 10, fontWeight: 700 }}>
                  FINANCIALS
                </Text>
              </TableCell>
            </TableHeader>
            <TableHeader>
              <TableCell
                weighting={0.11}
                style={{ ...styles.tableHeader, justifyContent: 'center' }}
              >
                <Text style={{ ...styles.textExSmallBold, fontSize: 9, fontWeight: 700 }}>
                  Room
                </Text>
              </TableCell>
              <TableCell
                weighting={0.0965}
                style={{ ...styles.tableHeader, justifyContent: 'center' }}
              >
                <Text style={{ ...styles.textExSmallBold, fontSize: 9, fontWeight: 700 }}>
                  Guest
                </Text>
              </TableCell>
              <TableCell
                weighting={0.11}
                style={{ ...styles.tableHeader, justifyContent: 'center' }}
              >
                <Text style={{ ...styles.textExSmallBold, fontSize: 9, fontWeight: 700 }}>
                  Room
                </Text>
              </TableCell>
              <TableCell
                weighting={0.096}
                style={{ ...styles.tableHeader, justifyContent: 'center' }}
              >
                <Text style={{ ...styles.textExSmallBold, fontSize: 9, fontWeight: 700 }}>
                  Guest
                </Text>
              </TableCell>
              <TableCell
                weighting={0.1}
                style={{ ...styles.tableHeader, justifyContent: 'center' }}
              >
                <Text style={{ ...styles.textExSmallBold, fontSize: 9, fontWeight: 700 }}>
                  Room
                </Text>
              </TableCell>
              <TableCell
                weighting={0.1}
                style={{ ...styles.tableHeader, justifyContent: 'center' }}
              >
                <Text style={{ ...styles.textExSmallBold, fontSize: 9, fontWeight: 700 }}>
                  %OCC
                </Text>
              </TableCell>
              <TableCell
                weighting={0.1}
                style={{ ...styles.tableHeader, justifyContent: 'center' }}
              >
                <Text style={{ ...styles.textExSmallBold, fontSize: 9, fontWeight: 700 }}>
                  Guest
                </Text>
              </TableCell>
              <TableCell
                weighting={0.1}
                style={{ ...styles.tableHeader, justifyContent: 'center' }}
              >
                <Text style={{ ...styles.textExSmallBold, fontSize: 9, fontWeight: 700 }}>
                  Revenue
                </Text>
              </TableCell>
              <TableCell
                weighting={0.1}
                style={{ ...styles.tableHeader, justifyContent: 'center' }}
              >
                <Text style={{ ...styles.textExSmallBold, fontSize: 9, fontWeight: 700 }}>
                  Gross Profit
                </Text>
              </TableCell>
              <TableCell
                weighting={0.1}
                style={{ ...styles.tableHeader, justifyContent: 'center' }}
              >
                <Text style={{ ...styles.textExSmallBold, fontSize: 9, fontWeight: 700 }}>
                  Net Profit
                </Text>
              </TableCell>
            </TableHeader>
          </TablePDF>
        </TableCell>
      </TableHeader>
      {tableData &&
        tableData.map(row => (
          <TableHeader key={row.date}>
            <TableCell
              weighting={0.1}
              style={{ ...styles.tableHeader, backgroundColor: 'transparent' }}
            >
              <Text style={{ fontSize: 10 }}>{row.date}</Text>
            </TableCell>
            <TableCell weighting={0.9}>
              <TablePDF
                weightings={[0.2, 0.2, 0.3, 0.3]}
                tdStyle={{ border: '1px solid transparent' }}
              >
                <TableHeader>
                  <TableCell
                    weighting={0.11}
                    style={{
                      ...styles.tableHeader,
                      justifyContent: 'center',
                      backgroundColor: 'transparent'
                      // border: '1px solid transparent'
                      // borderTop: '1px solid transparent'
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>{row.check_in?.room}</Text>
                  </TableCell>
                  <TableCell
                    weighting={0.0965}
                    style={{
                      ...styles.tableHeader,
                      backgroundColor: 'transparent',
                      justifyContent: 'center'
                      // borderBottom: 'none'
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>{row.check_in?.pax}</Text>
                  </TableCell>
                  <TableCell
                    weighting={0.11}
                    style={{
                      ...styles.tableHeader,
                      justifyContent: 'center',
                      backgroundColor: 'transparent'
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>{row.check_out?.room}</Text>
                  </TableCell>
                  <TableCell
                    weighting={0.096}
                    style={{
                      ...styles.tableHeader,
                      justifyContent: 'center',
                      backgroundColor: 'transparent'
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>{row.check_out?.pax}</Text>
                  </TableCell>
                  <TableCell
                    weighting={0.1}
                    style={{
                      ...styles.tableHeader,
                      justifyContent: 'center',
                      backgroundColor: 'transparent'
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>{row.occupied?.room}</Text>
                  </TableCell>
                  <TableCell
                    weighting={0.1}
                    style={{
                      ...styles.tableHeader,
                      justifyContent: 'center',
                      backgroundColor: 'transparent'
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>{row.occupied?.occupied_percent}%</Text>
                  </TableCell>
                  <TableCell
                    weighting={0.1}
                    style={{
                      ...styles.tableHeader,
                      justifyContent: 'center',
                      backgroundColor: 'transparent'
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>{row.occupied?.pax}</Text>
                  </TableCell>
                  <TableCell
                    weighting={0.1}
                    style={{
                      ...styles.tableHeader,
                      justifyContent: 'center',
                      backgroundColor: 'transparent'
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>{formatNumber(row.financials?.revenue)}</Text>
                  </TableCell>
                  <TableCell
                    weighting={0.1}
                    style={{
                      ...styles.tableHeader,
                      justifyContent: 'center',
                      backgroundColor: 'transparent'
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>
                      {formatNumber(row.financials?.gross_profit)}
                    </Text>
                  </TableCell>
                  <TableCell
                    weighting={0.1}
                    style={{
                      ...styles.tableHeader,
                      justifyContent: 'center',
                      backgroundColor: 'transparent'
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>{formatNumber(row.financials?.net_profit)}</Text>
                  </TableCell>
                </TableHeader>
              </TablePDF>
            </TableCell>
          </TableHeader>
        ))}
    </TablePDF>
  );
};

export default Table;
