import { Dropdown, Menu, Button } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { BookingLine } from 'services/api/type/booking.type';

export const renderRoomsDropdown = (
  record: BookingLine,
  availableRooms?: any[],
  onClickEdit?: (bookingLineId: number, selectedRoomId?: number) => void
) => {
  return (
    <Dropdown
      overlay={
        <Menu>
          {availableRooms?.map(room => (
            <Menu.Item
              key={room.value}
              disabled={room.disabled}
              onClick={({ domEvent }) => {
                domEvent.stopPropagation();
                if (onClickEdit) {
                  onClickEdit(record.booking_line_id, room.value);
                }
              }}
            >
              {room.label}
            </Menu.Item>
          ))}
        </Menu>
      }
      trigger={['click']}
    >
      <Button
        icon={<EditFilled />}
        onClick={event => {
          event.stopPropagation();
          if (onClickEdit) {
            onClickEdit(record.booking_line_id);
          }
        }}
        style={{ fontSize: '16px', marginLeft: '8px' }}
      />
    </Dropdown>
  );
};
