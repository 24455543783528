import { axiosInstance } from '../base.api';
import { paths } from '../paths';
import { TravelAgencyResponse, TravelAgencyParams } from '../type/travel-agency.type';

export const createTravelAgency = async (data: TravelAgencyParams): Promise<string> => {
  return axiosInstance.post(paths.travelAgencies(), data).then(response => response.data);
};

export const updateTravelAgency = async (
  id: number,
  data: TravelAgencyParams
): Promise<TravelAgencyResponse> => {
  return axiosInstance
    .put(paths.updateTravelAgency(id), data)
    .then(response => response.data.data[0]);
};

export const deleteTravelAgency = async (id: number): Promise<any> => {
  return axiosInstance.delete(paths.deleteTravelAgency(id)).then(response => response.data.data);
};

export const getTravelAgencies = async (values: any): Promise<TravelAgencyResponse[]> => {
  return axiosInstance
    .get(paths.travelAgencies(), {
      params: values
    })
    .then(response => response.data.data);
};

export const getTravelAgency = async (id: number): Promise<TravelAgencyResponse> => {
  return axiosInstance.get(paths.getTravelAgency(id)).then(response => response.data.data[0]);
};
