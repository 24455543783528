import React, { useState, useMemo, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, Table, Form, Input, Button } from 'antd';
import { useGetReservationForecast } from 'hooks/useGetReservationForecast';
import { toLocalTime, toUtcTime } from 'utils';
import { columnReservationForecast } from 'constants/table';
import 'styles/reservation-forecast.scss';
import { FormProps } from 'rc-field-form';
import _ from 'lodash';

import ExportPDF from 'components/common/export-pdf';

const INITIAL_RANGE_DATE = 14;
const initStartDate = dayjs().startOf('day');

function ReservationForecast() {
  const [form] = Form.useForm();

  const [filterOptions, setFilterOptions] = useState<any>({
    start_date: toUtcTime(initStartDate.add(1, 'day'), 'YYYY-MM-DD'),
    period: INITIAL_RANGE_DATE
  });
  const { data: reservationForecast, isFetching } = useGetReservationForecast(filterOptions);

  const dataSource = useMemo(() => {
    if (!reservationForecast) return [];
    const dataArray = reservationForecast.map(item => ({
      ...item,
      date: toLocalTime(item.date, 'DD/MM/YYYY ddd')
    }));

    const totalOccupiedPercent = dataArray.reduce(
      (sum, item) => sum + (item.occupied?.occupied_percent || 0),
      0
    );
    const totalRevenue = dataArray.reduce((sum, item) => sum + (item.financials?.revenue || 0), 0);
    const totalGrossProfit = dataArray.reduce(
      (sum, item) => sum + (item.financials?.gross_profit || 0),
      0
    );
    const totalNetProfit = dataArray.reduce(
      (sum, item) => sum + (item.financials?.net_profit || 0),
      0
    );
    const averageOccupiedPercent =
      dataArray.length > 0 ? totalOccupiedPercent / dataArray.length : 0;

    const averageRow = {
      date: 'TOTAL',
      check_in: { room: '', pax: '' },
      check_out: { room: '', pax: '' },
      occupied: {
        room: '',
        occupied_percent: averageOccupiedPercent.toFixed(2),
        pax: ''
      },
      financials: {
        revenue: totalRevenue,
        gross_profit: totalGrossProfit,
        net_profit: totalNetProfit
      }
    };

    return [...dataArray, averageRow];
  }, [reservationForecast]);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const { rangeDate, period, ...restFilterOptions } = truthyValues;
    if (rangeDate) {
      const startDate: Dayjs = rangeDate[0];
      const endDate: Dayjs = rangeDate[1];

      if (startDate && endDate) {
        const dayCount = endDate.diff(startDate, 'day');
        _.set(restFilterOptions, 'period', dayCount);
        _.set(restFilterOptions, 'start_date', toUtcTime(startDate.add(1, 'day'), 'YYYY-MM-DD'));
      }
    }

    _.set(restFilterOptions, 'period', period || INITIAL_RANGE_DATE);

    setFilterOptions(restFilterOptions);
  };

  const handleRangeDateChange = (dates: any) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      const dayCount = end.diff(start, 'day');
      form.setFieldsValue({ period: dayCount });
    }
  };

  const handlePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const period = parseInt(e.target.value);
    if (!isNaN(period)) {
      const startDate = dayjs(form.getFieldValue('rangeDate')[0]);
      const endDate = startDate.add(period, 'day');
      form.setFieldsValue({ rangeDate: [startDate, endDate] });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      rangeDate: [initStartDate, initStartDate.add(INITIAL_RANGE_DATE, 'day')],
      period: INITIAL_RANGE_DATE
    });

    // eslint-disable-next-line
  }, []);

  return (
    <div className="pms-reservation-forecast">
      <div className="pms-reservation-forecast__timetable">
        <Form
          form={form}
          initialValues={{
            rangeDate: [initStartDate, initStartDate.add(INITIAL_RANGE_DATE, 'day')],
            period: INITIAL_RANGE_DATE
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="pms-reservation-forecast__filter">
            <div className="pms-reservation-forecast__filter__left">
              <Form.Item name="rangeDate">
                <DatePicker.RangePicker
                  className="w-full"
                  placeholder={['Từ ngày', 'Đến ngày']}
                  format="DD-MM-YYYY"
                  onChange={handleRangeDateChange}
                />
              </Form.Item>
              <Form.Item name="period">
                <Input placeholder="Số ngày" onChange={handlePeriodChange} />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                  Tìm kiếm
                </Button>
              </Form.Item>
            </div>
            <ExportPDF
              titlePDF="Dự báo đặt phòng"
              enableView={isFetching}
              dataSource={dataSource}
              fromDate={_.get(form.getFieldValue('rangeDate'), '0')}
              toDate={_.get(form.getFieldValue('rangeDate'), '1')}
            />
          </div>
        </Form>
        <div className="pms-reservation-forecast__table">
          <Table
            rowKey="date"
            loading={isFetching}
            columns={columnReservationForecast}
            dataSource={dataSource}
            pagination={false}
            scroll={{ x: 1000, y: 'calc(100vh - 320px)' }}
            bordered
            locale={{
              emptyText: <span className="empty-data">Không có dữ liệu</span>
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ReservationForecast;
